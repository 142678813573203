import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/visiohub/src/app/(main)/UserStoresClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/visiohub/src/app/components/CanteenSelectBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/visiohub/src/app/components/MenuItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/visiohub/src/app/components/Openable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/visiohub/src/app/TypesenseApiKeyClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/visiohub/src/components/LogoutButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.26.3_next@14.2.21_@babel+core@7.26.8_@opentelemetry+api@1.9.0_react-dom@18.3.1_re_plbt3haribk5rr6fovvy5ou5le/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.21_@babel+core@7.26.8_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.21_@babel+core@7.26.8_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
